import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalServiceService } from '../services/local-service.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationConfirmationPromptComponent } from './focus/navbar/navigation-confirmation-prompt/navigation-confirmation-prompt.component';
import { Overlay } from '@angular/cdk/overlay';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private localService: LocalServiceService,public dialog: MatDialog, private overlay: Overlay) {}

  canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
   
    if (this.localService.getCampaignUnsavedChanges() || this.localService.getImportPatientUnsavedChanges()) {
      // return confirm('You have unsaved changes. Do you really want to leave?');

      const dialogRef = this.dialog.open(NavigationConfirmationPromptComponent, {
        disableClose: false,
        width: '480px',
        panelClass: `navigation-confirmation-modal`,
        scrollStrategy: this.overlay.scrollStrategies.noop(),
      });
  
      return dialogRef.afterClosed().toPromise().then(result => {
        if (result) {
          this.dialog.closeAll();
          return true;
        } else {
          return false;
        }
      });
    } else{
      return true
    }
    // return true

  }
}
