import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, importProvidersFrom } from '@angular/core';
import { GuardsCheckEnd, RouterModule } from '@angular/router';
import { LocalServiceService } from './services/local-service.service';
import { NavbarComponent } from './apps/focus/navbar/navbar.component';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, Event } from '@angular/router'
import { CallSlideupPopoverComponent } from './apps/focus/common-components/call-slideup-popover/call-slideup-popover.component';
import { ApisService } from './services/apis.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { take } from 'rxjs';
import { uiClientsConfig } from 'admin-ui-v2-config';
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, HttpClientModule, NavbarComponent, CallSlideupPopoverComponent, ColorPickerModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'src-admin-ui-v2';
  isLoading: boolean = true;
  appName: string = "";
  insideFocus: boolean = false;
  constructor(private router: Router, private localService: LocalServiceService, public api: ApisService,) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && event instanceof GuardsCheckEnd) {
        this.isLoading = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.isLoading = false;
      }
    });
  }

  async checkPermission(permissionName, subAction) {
    try {
      const result = await this.api.getValidPermission(permissionName, subAction).toPromise();

      if (result && result.data) {
        for (let i = 0; i < result.data.length; i++) {
          const words = result.data[i].subAction.toLowerCase().split("_");
          const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
          const keyName = capitalizedWords.join("");
          this.allPermission[keyName] = true;
        }
      }
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching permission:", error);
    }
  }


  allPermission = {
    "InboxFocusCallTab": false,
    "InboxFocusTasksTab": false,
    "InboxFocusMessagingTab": false,
    "InboxFocusMessagingSmsTab": false,
    "InboxFocusMessagingEmailTab": false,
    "InboxFocusMessagingPhoneTab": false,
    "InboxFocusMessagingTabConferenceButton": false,
    "InboxFocusMessagingTabTransferButton": false,
    "InboxFocusMessagingTabCompleteButton": false,
    "InboxFocusMessagingTabMessageAndCompleteButton": false,
    "InboxFocusCallTabClientProtocols": false,
    "InboxFocusCallTabViewActivityLog": false,
    "InboxFocusCallSmsTab": false,
    "InboxFocusCallEmailTab": false,
    "InboxFocusCallPhoneTab": false,
    "InboxFocusCallTabConferenceButton": false,
    "InboxFocusCallTabTransferButton": false,
    "InboxFocusCallTabCompleteButton": false,
    "InboxFocusCallTabPayment": false,
    "InboxFocusCallTabSearchButton": false,
    "InboxFocusCallTabCreatePatientButton": false,
    "InboxFocusCallTabAssociateButton": false,
    "FocusDashboardLiveVoice": false,
    "FocusDashboardDigitalEngagement": false,
    "FocusDashboardRealtime": false

  }
  async getCommunicationTabPermission():Promise<{FocusBroadCastIVR: boolean, FocusBroadCastSMS: boolean} | {}> {
    try {
      const result = await this.api.getCommunicationTabPermission(uiClientsConfig.customerUrlPrefix).toPromise();
      const focusBroadCastPermission = {
        FocusBroadCastIVR: result?.data?.focusBrandConfiguration?.ivrService ?? false,
        FocusBroadCastSMS: result?.data?.focusBrandConfiguration?.smsService ?? false,
        FocusBroadCastAvailableLanguages: result?.data?.focusBrandConfiguration?.showAvailibleLanguages ?? { es: false, ru: false },

      };
      return focusBroadCastPermission;
    } catch (error) {
      console.error("Error fetching communication tab permission:", error);
      return {};  
    }
  }
  
  
  async ngOnInit(): Promise<void> {
    // this.localService.setSessionId();
    this.appName = window.location.pathname.split("/")[1];
    if (this.appName === 'focus') {
      this.insideFocus = true;
    }
    let uiPermissions = [
      { permissionName: "Inbox Focus Get", subAction: true },
      { permissionName: "Inbox Focus Post", subAction: true },
      { permissionName: "Inbox Focus Put", subAction: true },
      { permissionName: "Inbox Focus Delete", subAction: true },
      { permissionName: "Focus Dashboard Get", subAction: true },
    ];

    const permissionPromises = uiPermissions.map(permission => {
      return this.checkPermission(permission.permissionName, permission.subAction);
    });

    const focusBroadCastPermission = await this.getCommunicationTabPermission();
    await Promise.all(permissionPromises);
    this.localService.setBroadCastPermissions(focusBroadCastPermission);
    localStorage.setItem("Uipermission", JSON.stringify(this.allPermission));
    if (window.performance.navigation.type == performance.navigation.TYPE_RELOAD && window.localStorage.getItem("sessionId")) {
      window.sessionStorage.setItem("sessionId", window.localStorage.getItem("sessionId"));
      window.localStorage.removeItem("sessionId");
    } else {
      window.localStorage.removeItem("sessionId");
      this.localService.setSessionId();
    }
    this.api.getAllUserApplications().then((result: any)=>{
      localStorage.setItem("userApplications", JSON.stringify(result["data"]["applications"]));
    });
  }
}
