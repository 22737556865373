import { Component, Inject, OnInit, Optional } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination/lib/pagination-instance';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisallowSpacesDirective } from 'src/app/directive/disallow-spaces.directive';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { Overlay } from '@angular/cdk/overlay';
import { CreateNewPatientModalComponent } from '../../../patients/create-new-patient-modal/create-new-patient-modal.component';
import { SearchOrAssociatePatientModalService } from './search-or-associate-patient-modal.service';
import { InboxService } from '../../inbox.service';
import { RandomBackgroundColorGeneratorDirective } from 'src/app/directive/random-backgroundcolor-generator.directive';
import { MatRadioModule } from '@angular/material/radio';
import { ToastrModule, ToastrService, } from 'ngx-toastr';
import { LocalServiceService } from 'src/app/services/local-service.service';
import * as moment from 'moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpErrorResponse } from '@angular/common/http';
import { searchMember, paginationResult, associateMemberResult, callAuditParameters, unknownPatientObject } from './search-or-associate-patient-modal-interface';
import { customWindow, permission } from 'src/custom';
import { Subscription } from 'rxjs';
import { callerDetail } from '../interface'
import { uiClientsConfig } from 'admin-ui-v2-config';
import { patientDetailsResult } from '../../../patients/patient-profile/patient-profile-interface';


export interface patientSearchObj {
  firstName: string,
  lastName: string,
  gender: string,
  emailAddress: string,
  cellPhone: string | null | undefined,
  guarantorPhoneNumber: string | null | undefined,
  dateOfBirth: string,
  externalId: string | null,
  initials: string,
  patientId: string,
  patientOauthUserId?: string | null,
  profilePictureUrl: string | null
}
export interface DateOfBirthObject {
  dateOfBirth: string,
  dobMonth: number | null,
  dobYear: string,
  dobDay: number | null,
}
export interface pageObject {
  pageNum: number,
  selected: boolean,
}

@Component({
  selector: 'app-search-or-associate-patient-modal',
  standalone: true,
  imports: [CommonModule, DisallowSpacesDirective, FormsModule, MatSelectModule, CreateNewPatientModalComponent, MatRadioModule, RandomBackgroundColorGeneratorDirective, ToastrModule, NgxPaginationModule, MatInputModule, MatAutocompleteModule],
  templateUrl: './search-or-associate-patient-modal.component.html',
  styleUrls: ['./search-or-associate-patient-modal.component.css']
})



export class SearchOrAssociatePatientModalComponent implements OnInit {

  constructor(
    public searchOrAssociatePatientModalService: SearchOrAssociatePatientModalService,
    public dialog: MatDialog, public dialogRef: MatDialogRef<SearchOrAssociatePatientModalComponent>,
    public overlay: Overlay, private inboxService: InboxService,
    private toastr: ToastrService,
    private localService: LocalServiceService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      allPermission: permission,
      isOpenedForOutboundCalling: boolean,
      formattedPhoneNumber: string,
      disableCallLogging?: boolean
    }) { }

  isUnknownPatient: Subscription = new Subscription();
  window: customWindow = window;
  associatedPatientOrgId: string = "";
  imgUrlPrefix = "https://" + uiClientsConfig.customerUrlPrefix + "-admin." + uiClientsConfig.reservedUrlDomain;
  nullUrl = this.imgUrlPrefix + "/null";
  defaultUserImgUrl = this.imgUrlPrefix + "/img/default-user.png";
  isLoading: boolean = false;
  selectedPatient: string = "";
  isOpenedForOutboundCalling: boolean = false;
  emptySearch: boolean = true;
  allPermission: permission = this.data.allPermission
  patientSearchArray: patientSearchObj[] = [];
  patientSearchArrayCopy: patientSearchObj[] | null = [];
  associatePatientBtnClicked: boolean = false;
  createPatientBtnClicked: boolean = false;
  disableCallLogging: boolean = false;
  unknownPatientSearchObj: unknownPatientObject = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    emailAddress: "",
    cellPhone: "",
    pageNumber: 0
  };
  totalPages: number = 0;
  limit: number = 3;
  page: number = 1;
  totalRecords: number = 0;
  dateOfBirthObject: DateOfBirthObject = {
    dateOfBirth: '',
    dobMonth: null,
    dobYear: '',
    dobDay: null,
  };
  isAssociateDisabled: boolean = true;
  searchedPatientName: string = "";
  emailRegex: RegExp = /^[\S]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  nameRegex = /^[A-Za-z0-9 ]+$/;
  phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  noData: boolean = false;
  day: string = '';
  days: string[] = [];
  isValid: boolean = false;
  formattedDialNumber: string = '';
  temp: number = 1;
  pageObject: pageObject[] = [];
  isPatientAssociated: boolean = false;
  months = [
    {
      id: '01',
      displayName: 'January',
      maxDays: 31,
    },
    {
      id: '02',
      displayName: 'February',
      maxDays: 29,
    },
    {
      id: '03',
      displayName: 'March',
      maxDays: 31,
    },
    {
      id: '04',
      displayName: 'April',
      maxDays: 30,
    },
    {
      id: '05',
      displayName: 'May',
      maxDays: 31,
    },
    {
      id: '06',
      displayName: 'June',
      maxDays: 30,
    },
    {
      id: '07',
      displayName: 'July',
      maxDays: 31,
    },
    {
      id: '08',
      displayName: 'August',
      maxDays: 31,
    },
    {
      id: '09',
      displayName: 'September',
      maxDays: 30,
    },
    {
      id: '10',
      displayName: 'October',
      maxDays: 31,
    },
    {
      id: '11',
      displayName: 'November',
      maxDays: 30,
    },
    {
      id: '12',
      displayName: 'December',
      maxDays: 31,
    },
  ];

  //change days in date of birth dropdown according to month
  setDays() {
    for (let j = 0; j < this.months.length; j++) {
      if (this.months[j].id == this.dateOfBirthObject.dobMonth?.toString()) {
        this.days = []; // Reset days array before adding new days
        for (let i = 1; i <= this.months[j].maxDays; i++) {
          if (i < 10) {
            this.day = "0" + i;
            this.days.push(this.day.toString());
          }
          else {
            this.days.push(i.toString());
          }
        }
      }
    }
  }

  checkIfNumber(event: any) {
    /**check the value of the keyPress to make sure it's a num. which from 48-57 are 0-9 */
    if ((event.which !== 8 && event.which < 48) || event.which > 57) {
      event.preventDefault();
    }
  }

  // validate pasted text
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text/plain');
    if (pastedText) {
      const isNumeric = /^\d+$/.test(pastedText); // Check if pasted text is numeric
      if (!isNumeric) {
        event.preventDefault(); // Prevent the paste action
      }
    }
  }

  clearSelection() {
    this.dateOfBirthObject.dobDay = null;
  }

  // format phone number for UI
  formatNumber() {
    var filterPhoneNumber = function (phoneNumber: string) {
      if (!phoneNumber) { return ''; }
      var value = phoneNumber.toString().trim().replace(/^\+/, '');
      if (value.match(/[^0-9]/)) {
        return phoneNumber;
      }
      var number = value;
      if (number) {
        if (number.length > 6) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6, 10);
        }
        else if (number.length > 3) {
          number = number.slice(0, 3) + '-' + number.slice(3, 6);
        } return ('+1 ' + number).trim();
      }
      else { return '+1 ' + number; }
    };
    this.formattedDialNumber = this.formattedDialNumber.replace(/-/g, "");
    if (this.formattedDialNumber.includes('+')) { this.formattedDialNumber = this.formattedDialNumber.slice(3); }
    this.formattedDialNumber = filterPhoneNumber(this.formattedDialNumber);
    this.unknownPatientSearchObj.cellPhone = this.formattedDialNumber.replace(/[^\d+]/g, "").slice(2);
  }

  monthName(value1: string) {
    if (value1) {
      if (this.months?.find(month => month.id === value1)) {
        return this.months?.find(month => month.id === value1)?.displayName;
      }
    }
    return null;
  }

  validatePatientInfoExtendedInfo() {
    this.isValid = true;
    let isLeapYear =
      parseInt(this.dateOfBirthObject.dobYear) % 100 === 0
        ? parseInt(this.dateOfBirthObject.dobYear) % 400 === 0
        : parseInt(this.dateOfBirthObject.dobYear) % 4 === 0;

    //validations
    if (!this.unknownPatientSearchObj.firstName && !this.unknownPatientSearchObj.lastName && !this.dateOfBirthObject.dobMonth && !this.dateOfBirthObject.dobDay && !this.dateOfBirthObject.dobYear && !this.unknownPatientSearchObj.emailAddress && !this.formattedDialNumber) {
      this.toastr.warning(`Please enter any field`);
      this.isValid = false;
    } else {
      if (this.unknownPatientSearchObj && this.unknownPatientSearchObj.firstName.length > 60) {
        this.toastr.warning(`First name should be less than 60 characters`);
        this.isValid = false;
      }
      else if (this.unknownPatientSearchObj && this.unknownPatientSearchObj.firstName.trim() && !this.nameRegex.test(this.unknownPatientSearchObj.firstName)) {
        this.toastr.warning(`Please enter valid First Name`);
        this.isValid = false;
      }
      else if (this.unknownPatientSearchObj && this.unknownPatientSearchObj.lastName.length > 60) {
        this.toastr.warning(`Last name should be less than 60 characters`);
        this.isValid = false;
      }
      else if (this.unknownPatientSearchObj && this.unknownPatientSearchObj.lastName.trim() && !this.nameRegex.test(this.unknownPatientSearchObj.lastName)) {
        this.toastr.warning(`Please enter valid Last Name`);
        this.isValid = false;
      }
      else if (this.unknownPatientSearchObj && this.unknownPatientSearchObj.emailAddress && this.unknownPatientSearchObj.emailAddress && !this.emailRegex.test(this.unknownPatientSearchObj.emailAddress)) {
        this.toastr.warning(`Enter a valid Confidential Email`);
        this.isValid = false;
      }
      else if (this.formattedDialNumber && !this.phoneRegex.test(this.formattedDialNumber)) {
        this.toastr.warning(`Please enter valid Phone Number`);
        this.isValid = false;
      }
      else if (this.dateOfBirthObject && this.dateOfBirthObject.dobYear && !this.dateOfBirthObject.dobMonth) {
        this.toastr.warning(`Please enter your  DOB Month`);
        this.isValid = false;
      }
      else if (this.dateOfBirthObject && (this.dateOfBirthObject.dobMonth || this.dateOfBirthObject.dobYear) && !this.dateOfBirthObject.dobDay) {
        this.toastr.warning(`Please enter your  DOB Day`);
        this.isValid = false;
      }
      else if (this.dateOfBirthObject && (this.dateOfBirthObject.dobMonth || this.dateOfBirthObject.dobDay) && !this.dateOfBirthObject.dobYear) {
        this.toastr.warning(`Please enter your  DOB Year`);
        this.isValid = false;
      }
      else if (this.isValid && this.dateOfBirthObject.dobMonth && this.dateOfBirthObject.dobDay && this.dateOfBirthObject.dobYear) {
        if (this.dateOfBirthObject.dobMonth < 10 && this.dateOfBirthObject.dobDay >= 10) {
          this.unknownPatientSearchObj.dateOfBirth = `${this.dateOfBirthObject.dobYear}-${this.dateOfBirthObject.dobMonth}-${this.dateOfBirthObject.dobDay}`;
        } else if (this.dateOfBirthObject.dobDay < 10 && this.dateOfBirthObject.dobMonth >= 10) {
          this.unknownPatientSearchObj.dateOfBirth = `${this.dateOfBirthObject.dobYear}-${this.dateOfBirthObject.dobMonth}-${this.dateOfBirthObject.dobDay}`;
        } else if (this.dateOfBirthObject.dobDay < 10 && this.dateOfBirthObject.dobMonth < 10) {
          this.unknownPatientSearchObj.dateOfBirth = `${this.dateOfBirthObject.dobYear}-${this.dateOfBirthObject.dobMonth}-${this.dateOfBirthObject.dobDay}`;
        } else {
          this.unknownPatientSearchObj.dateOfBirth = `${this.dateOfBirthObject.dobYear}-${this.dateOfBirthObject.dobMonth}-${this.dateOfBirthObject.dobDay}`;
        }
        var date = moment(this.unknownPatientSearchObj.dateOfBirth);
        if (date.isValid()) {
          var currentDateTime = new Date();
          var expirationDateTime = new Date(this.unknownPatientSearchObj.dateOfBirth);
          if (expirationDateTime.getTime() > new Date().getTime()) {
            this.toastr.warning("Birth date can't be greater than or equal to the current date");
            this.isValid = false;
          } else if (+(this.dateOfBirthObject.dobYear) < 1900) {
            this.toastr.warning("Birth year should be greater than or equal to 1900.");
            this.isValid = false;
          } else if (expirationDateTime.getMonth() === currentDateTime.getMonth() && expirationDateTime.getFullYear() === currentDateTime.getFullYear() && expirationDateTime.getDate() === currentDateTime.getDate()) {
            this.toastr.warning("Birth date can't be greater than or equal to the current date");
            this.isValid = false;
          } else if (
            this.dateOfBirthObject.dobMonth.toString() == "02" &&
            this.dateOfBirthObject.dobDay > 28 &&
            !isLeapYear
          ) {
            this.toastr.warning('Invalid birth date');
            this.isValid = false;
          }
          else {
            this.isValid = true;
          }
        } else {
          this.toastr.warning("Date of birth is invalid");
          this.isValid = false;
        }
      }
    }
    return this.isValid;
  }

  public config: PaginationInstance =
    {
      id: 'server',
      itemsPerPage: this.limit,
      currentPage: this.page,
      totalItems: this.totalRecords
    };

  getSearchOrCollectionData(page: number) {
    this.config.currentPage = page;
    if (this.temp != page) {
      this.temp = page;
      this.searchUnknownPatient(page);
      // if (this.searchString && this.searchString !== "") { this.getWorkQueues(page); }
      // else { this.getCollectionData(page); }
    }
  }

  searchUnknownPatient(page: number) {
    this.isLoading = true;
    if (this.validatePatientInfoExtendedInfo()) {
      this.searchPatientData();
    }
    else {
      this.isLoading = false;
      this.patientSearchArray = [];
      this.patientSearchArrayCopy = [];
      this.totalRecords = 0;
      this.pageObject = [];
    }
  }

  searchPatientData() {
    this.isLoading = true;
    this.unknownPatientSearchObj.pageNumber = 1;
    this.isUnknownPatient = this.searchOrAssociatePatientModalService.searchUnknownPatient(this.unknownPatientSearchObj).subscribe({
      next: (result: searchMember) => {
        this.isLoading = false;
        if (result.data.length > 0) {
          this.totalRecords = result.count;
          // this.totalRecords = 0;
          this.pageObject = [];
          // this.temp = 1;
          this.config.currentPage = 1;
          if (this.totalRecords) this.emptySearch = false;
          else this.emptySearch = true;
          this.patientSearchArray = result.data.map(({ firstName, lastName, dateOfBirth, gender, cellPhone, guarantorPhoneNumber, emailAddress, patientId, externalId, profilePictureUrl, initials }) => ({ firstName, lastName, dateOfBirth, gender, cellPhone, guarantorPhoneNumber, emailAddress, patientId, externalId, profilePictureUrl: this.imgUrlPrefix + "/" + profilePictureUrl, initials }))
          for (let i = 0; i < this.patientSearchArray.length; i++) {
            if ((this.patientSearchArray[i].dateOfBirth) && (this.patientSearchArray[i].dateOfBirth != '0000-00-00' || this.patientSearchArray[i].dateOfBirth != '00-00-0000' || this.patientSearchArray[i].dateOfBirth != '00/00/0000' || this.patientSearchArray[i].dateOfBirth != '0000/00/00')) {
              this.patientSearchArray[i].dateOfBirth = moment(this.patientSearchArray[i].dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY');
            }
            this.patientSearchArray[i].cellPhone = this.patientSearchArray[i].cellPhone ? this.patientSearchArray[i].cellPhone?.replace(/^(\+?1)?(\d{3})(\d{3})(\d{4})$/, "+1 $2-$3-$4") : this.patientSearchArray[i].cellPhone;
            this.patientSearchArray[i].guarantorPhoneNumber = this.patientSearchArray[i].guarantorPhoneNumber ? this.patientSearchArray[i].guarantorPhoneNumber?.replace(/^(\+?1)?(\d{3})(\d{3})(\d{4})$/, "+1 $2-$3-$4") : this.patientSearchArray[i].guarantorPhoneNumber;

          }
          this.patientSearchArrayCopy = this.patientSearchArray;
          this.noData = false;
          this.getPagination(1)
        }
        else {
          this.noData = true;
          this.patientSearchArray = [];
          this.patientSearchArrayCopy = [];
          this.totalRecords = 0;
          this.pageObject = [];
          this.temp = 1;
          this.config.currentPage = 1;
        }
      },
      error: (error: HttpErrorResponse) => { console.log("An error occurred in searching associated patients", error); }
    })
  }

  createPatient() {
    this.createPatientBtnClicked = true;
    this.dialogRef.close({ openCreatePatientModal: true });
  }
  associateMember() {
    this.associatePatientBtnClicked = true;
    this.inboxService.getCallPickedDetail("", this.selectedPatient).subscribe((result: patientDetailsResult) => {
      if (result) {
        if (!this.disableCallLogging) {
          this.localService.getCallAuditParameters().subscribe({
            next: (callAuditParameters: callAuditParameters) => {
              let knownPatientCallAudit = {
                patientType: "KNOWN_PATIENT",
                callSid: callAuditParameters.callSid,
                fromNumber: callAuditParameters.fromNumber,
                toNumber: callAuditParameters.toNumber,
                rawCallData: callAuditParameters.rawCallData,
                providerId: callAuditParameters.providerId,
                orgEntityId: result.data ? result.data[0].orgEntityId : null,
                isOutboundCall: callAuditParameters.rawCallData?.attributes?.isOriginalCallOutbound ? true : false
              }
              if (knownPatientCallAudit.callSid) {
                this.inboxService.sendTwilioCallAuditStatus(knownPatientCallAudit);
              }
            }
          })
        }
        this.dialogRef.close(result);
      }
    })
  }

  onRadioSelection(event: any) {
    this.selectedPatient = event.value;
    this.isAssociateDisabled = false;
  }
  closeModal() {
    this.isPatientAssociated = JSON.parse(window.localStorage.getItem("isPatientAssociated") ?? "false");
    if ((this.data?.formattedPhoneNumber || !this.isPatientAssociated) && !this.associatedPatientOrgId) {
      this.asUnknownPatient();
    } else {
      this.dialogRef.close();
    }
  }

  getPagination(page: number) {
    if (this.patientSearchArray.length > 1) {
      this.totalPages = Math.ceil(this.patientSearchArray.length / this.limit);
      let start: number = page * this.limit - this.limit;
      let end: number = page * this.limit;
      if (end > this.patientSearchArray.length) { end = this.patientSearchArray.length; }
      this.patientSearchArrayCopy = this.patientSearchArray.slice(start, end);
      this.config.currentPage = page;
      this.pageObject = [];
      if (page > 1) {
        this.pageObject.push(
          {
            pageNum: page - 1,
            selected: false,
          }
        );
      }
      this.pageObject.push(
        {
          pageNum: page,
          selected: false,
        }
      );
      if (page < this.totalPages) {
        this.pageObject.push(
          {
            pageNum: page + 1,
            selected: false,
          }
        );
      }
      if (page === 1) {
        this.pageObject[0].selected = true;
      } else {
        this.pageObject[1].selected = true;
      }
    }
  }

  // continue as unknown patient
  asUnknownPatient() {
    this.isOpenedForOutboundCalling = false;
    window.localStorage.setItem("continueAsUnknown", String(true));
    this.localService.setAssociatedPatientOrgId("UNKNOWN_PATIENT");
    this.dialogRef.close();
  }
  displayCreatePatient: boolean = true;
  ngOnInit(): void {
    this.displayCreatePatient = this.window["displayCreatePatient"];
    window.localStorage.setItem("continueAsUnknown", String(false));
    this.formattedDialNumber = this.data?.formattedPhoneNumber;
    if (this.data?.disableCallLogging) {
      this.disableCallLogging = this.data?.disableCallLogging;
    }
    this.unknownPatientSearchObj.cellPhone = this.formattedDialNumber ? this.formattedDialNumber.replace(/[^\d+]/g, "").slice(2) : "";
    this.isOpenedForOutboundCalling = this.data.isOpenedForOutboundCalling
    if (this.unknownPatientSearchObj.cellPhone) { this.searchUnknownPatient(1); }

    this.localService.isCallLeaveOrHangup.subscribe((result: string) => {
      if (result === "RESERVATION_ENDED" || result === "RESERVATION_CANCELED" || result === "RESERVATION_TIMEOUT") {
        this.dialogRef.close();
      }
    });

    // this.localService.recievedCallerObject.subscribe((result: any) => {
    //   this.searchedPatientName = result.name;
    // });
    this.localService.currentAssociatedPatientDetail.subscribe({
      next: (result: callerDetail) => {
        if (result) {
          this.searchedPatientName = result.patientName;
        }
      }
    })

    this.localService.getAssociatedPatientOrgId().subscribe((result: string) => {
      this.associatedPatientOrgId = result;
    });
  }

  ngOnDestroy(): void {
    if (!this.createPatientBtnClicked && !this.associatePatientBtnClicked && (!this.associatedPatientOrgId || this.associatedPatientOrgId === "UNKNOWN_PATIENT")) {
      this.localService.setAssociatedPatientOrgId("UNKNOWN_PATIENT");
      window.localStorage.setItem("continueAsUnknown", "true")
      this.createPatientBtnClicked = false;
      this.associatePatientBtnClicked = false;
    }
    if (Object.keys(this.isUnknownPatient).length) {
      this.isUnknownPatient.unsubscribe();
    }

  }

}
