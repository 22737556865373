import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpErrorResponse, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, from, throwError } from 'rxjs';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth-service';
import { uiClientsConfig, reservedCodeCategories } from 'admin-ui-v2-config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  redirectURI: string;

  constructor(private authService: AuthService, private cookieService: CookieService, private location: Location) { }

  appsRedirectUrl = "https://" + uiClientsConfig.customerUrlPrefix + "-admin." + uiClientsConfig.reservedUrlDomain;
  appsRedirectURl2 = "https://" + uiClientsConfig.customerUrlPrefix + "-focus." + uiClientsConfig.reservedUrlDomain;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var customerUrlPrefix = window.location.hostname.split("-")[0];
    customerUrlPrefix = customerUrlPrefix + "-admin"
    var cookie = this.cookieService.get(customerUrlPrefix);
    var tokenCookie;
    var authToken;
    if (cookie) {
      tokenCookie = JSON.parse(cookie);
      authToken = tokenCookie.access_token
    } else {
      this.logout();
      return null;
    }
    if (request.url == '/api/v1/idp/oauth2-token') {
      return next.handle(request);
    }
    var now = new Date();
    let accessToken = (tokenCookie && tokenCookie.access_token_expires_at) ? tokenCookie.access_token_expires_at : '';
    let refreshToken = (tokenCookie && tokenCookie.refresh_token_expires_at) ? tokenCookie.refresh_token_expires_at : '';
    var accessTokenDate = new Date(accessToken);
    var refreshTokenDate = new Date(refreshToken);
    if (now.getTime() >= accessTokenDate.getTime() && now.getTime() >= refreshTokenDate.getTime()) {
      this.logout();
      return null;
    }
    return from(this.authService.checkTokenEx()).pipe(
      switchMap((newToken: any) => {
        if (!newToken) {
          this.logout();
          return null;
        }
        // Add the access token to the request headers
        const authReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${newToken.access_token}`
          }
        });
        // Send the updated request
        return next.handle(authReq).pipe(
          catchError((error: HttpErrorResponse) => {
            // Rethrow the error so it can be caught by other error handlers
            return throwError(error);
          })
        );
      }),
      catchError((error) => {
        // Handle authentication errors
        // console.error(error);
        return throwError(error);
      })
    );
  }

  private async logout(): Promise<void> {
    var customerUrlPrefix = window.location.hostname.split("-")[0];
    customerUrlPrefix = customerUrlPrefix + "-admin"
    var authToken = this.cookieService.get(customerUrlPrefix);

    var isLoggedIn = false;
    var isLoginPageLoading = true;

    this.redirectURI = this.location.path(true);
    let exactRedirectURI = this.appsRedirectURl2 + this.redirectURI;

    if (!authToken) {
      var authUrl = this.appsRedirectUrl + "/auth?redirecturl=" + encodeURIComponent(exactRedirectURI) + "&client_id=" + reservedCodeCategories.reservedPasswordGrantTypeClientId;
      this.cookieService.delete(customerUrlPrefix);
      window.location.href = authUrl;
      return
    }

    try {
      await this.authService.logoutOauthUser(JSON.parse(authToken));
      isLoggedIn = false;
      isLoginPageLoading = false;

      this.cookieService.delete(customerUrlPrefix);
      authToken = null;


      var authUrl = this.appsRedirectUrl + "/auth?redirecturl=" + encodeURIComponent(exactRedirectURI) + "&client_id=" + reservedCodeCategories.reservedPasswordGrantTypeClientId;
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error logging out', error);
      // Handle errors here, e.g. display an error message to the user
    }
  }
}

