import { Injectable, OnDestroy } from "@angular/core";
import { TwilioWorkerService } from "src/app/services/worker.service";
import { Device } from "@twilio/voice-sdk";
import { LocalServiceService } from "src/app/services/local-service.service";
import { ApisService } from "src/app/services/apis.service";
import { Router } from "@angular/router";
import { customWindow } from "src/custom";
import { activities, workerAttributes } from "../apps/focus/navbar/interface";
import { attributes, reservation, workspace } from "../apps/focus/common-components/call-slideup-popover/interface";
import { uiClientsConfig } from 'admin-ui-v2-config';

interface TwilioDetails {
  device: Device,
  worker: workerOfWorkerInstance,
  workerId: string,
  workerName: string
};

export interface getTwilioDeviceOrWorkerToken {
  token: string;
}

export interface workerOfWorkerInstance {
  statistics: any,
  available: boolean,
  workspaceSid: string,
  workspace: workspace,
  dateUpdated: string,
  activitySid: string,
  sid: string,
  friendlyName: string,
  accountSid: string,
  dateStatusChanged: string,
  dateCreated: string,
  attributes: workerAttributes,
  activityName: string;
  version: number,
  operatingUnitSid: string;
  resourceUrl: string;
  token: string;
  workspaceUrl: string;
  apiRequester: any;
  update(arg1: string, arg2: string): void;
}

@Injectable({
  providedIn: "root"
})

export class TwilioConnection implements OnDestroy {
  window: customWindow = window;
  subDomain = window.location.host.split("-focus")[0];
  pendingReservations: boolean = false;
  workerInstance: any;
  twilioWorkspaceActivities: activities[] = [];
  deviceInstance: Device = {} as Device;
  Twilio: any;
  offlineActivity: activities = {} as activities;
  onCallActivity: activities = {} as activities;
  afterCallActivity: activities = {} as activities;
  makingCallsActivity: activities = {} as activities;
  twilioDetails: TwilioDetails = {
    device: {} as Device,
    worker: {} as workerOfWorkerInstance,
    workerId: "",
    workerName: ""
  };
  userStatus: string = "";
  constructor(private twilioWorkerService: TwilioWorkerService, private localService: LocalServiceService, private apisService: ApisService, private router: Router) {
    this.Twilio = this.window["Twilio"];
    this.window["windowTwilioWorkerInstance"] = undefined;
    this.window["availableActivity"] = undefined;
    this.window["offlineActivity"] = undefined;
    this.window["onCallActivity"] = undefined;
    this.window["afterCallActivity"] = undefined;
    this.window["makingCallsActivity"] = undefined;
    this.window["twilioActivityUpdateFunction"] = undefined;
    this.window["twilioActivityUpdateFunction"] = (incomingActivityId: string) => {
      if (this.window["windowTwilioWorkerInstance"]?.worker?.update) {
        this.window["windowTwilioWorkerInstance"].worker.update("ActivitySid", incomingActivityId || this.window["availableActivity"].id);
      }
    };
    this.localService.receiveTwilioCallNotification.subscribe(({ reservation, callStatus }) => {
      if (callStatus === "CALL_COMPLETED") {
        //do something
      }
    })
    this.localService.receiveTwilioUserStatus.subscribe((result: string) => {
      this.userStatus = result;
    })
  }

  public fetchPendingReservations(callback: CallableFunction) {
    let returnObject = { hasPendingReservations: false, totalPendingReservations: 0 }
    if (this.workerInstance?.fetchReservations) {
      this.workerInstance.fetchReservations(async (error: any | null, reservations: reservation) => {
        if (error) {
          console.log(error.code);
          console.log(error.message);
          callback(returnObject);
        }
        const data = reservations.data;
        returnObject = { hasPendingReservations: data?.length ? true : false, totalPendingReservations: data?.length ? data.length : 0 };
        callback(returnObject);
      }, {
        "ReservationStatus": "pending"
      });
    }
    else {
      callback(returnObject)
    }
  };

  completeAcceptedReservations(callback: CallableFunction) {
    this.workerInstance.fetchReservations(async (error: any | null, reservations: reservation) => {
      if (error) {
        console.log(error.code);
        console.log(error.message);
        return;
      }
      const data = reservations.data || [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].task.assignmentStatus === "wrapping" || data[i].task.assignmentStatus === "assigned") {
          this.pendingReservations = true;
          try {
            data[i].task.complete(function (error: any | null) {
              if (error) {
                console.log(error);
              }
            });
            navigator.sendBeacon(`https://${window.location.hostname.split("-")[0]}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints/complete-conference`,
              JSON.stringify({
                conferenceSid: data[i].task.attributes.conference.sid,
                customerCallSid: data[i].task.attributes.conference.participants.customer
              })
            );
          } catch (error) {
            console.log(error);
          }
        }
      }
      callback();
    }, {
      "ReservationStatus": "accepted"
    });
  };

  public connectTwilioWorker(workerId: string, twilioWorkspaceActivities: activities[], isTwilioOutboundCalls: boolean) {
    let twilioJWTToken = "";
    /** Store workerId into local Storage */
    this.twilioWorkspaceActivities = twilioWorkspaceActivities;
    // window.localStorage.setItem("twilioWorkerId", workerId);
    this.twilioDetails.workerId = workerId;
    this.window["availableActivity"] = this.twilioWorkspaceActivities.find((activity: activities) => activity.available && (activity.friendlyName).toUpperCase() === "AVAILABLE");
    this.offlineActivity = this.window["offlineActivity"] = this.twilioWorkspaceActivities.find((activity: activities) => !activity.available && (activity.friendlyName).toUpperCase() === "OFFLINE") || {} as activities;
    this.onCallActivity = this.window["onCallActivity"] = this.twilioWorkspaceActivities.find(
      (activity: activities) => !activity.available && ((activity.friendlyName).toUpperCase() === "ON_CALL" || (activity.friendlyName).toUpperCase() === "ONCALL")) || {} as activities;
    this.afterCallActivity = this.window["afterCallActivity"] = this.twilioWorkspaceActivities.find(
      (activity: activities) => !activity.available && ((activity.friendlyName).toUpperCase() === "AFTERCALL" || (activity.friendlyName).toUpperCase() === "AFTER_CALL")) || {} as activities;
    this.makingCallsActivity = this.window["makingCallsActivity"] = this.twilioWorkspaceActivities.find(
      (activity: activities) => activity.available && (activity.friendlyName).toUpperCase() === "MAKING_CALLS") || {} as activities;

    if (!this.window["twilioWorkerRegistered"]) {
      this.twilioWorkerService.getTwilioWorkerToken(this.twilioDetails.workerId).then((result: getTwilioDeviceOrWorkerToken) => {
        twilioJWTToken = result.token;
        this.initiationTwilioWorker(isTwilioOutboundCalls, result.token);
      });

      this.window["intervalId"] = setInterval(() => {
        if (!this.window["windowTwilioWorkerInstance"]) {
          this.initiationTwilioWorker(isTwilioOutboundCalls, twilioJWTToken);
        }
        else {
          if (this.window["intervalId"]) {
            clearInterval(this.window["intervalId"]);
          }
        }
      }, 3000);
    } else {
      if (this.window["twilioActivityUpdateFunction"] &&
        (typeof this.window["twilioActivityUpdateFunction"] === "function")) {
        this.window["twilioActivityUpdateFunction"](isTwilioOutboundCalls ? this.window["makingCallsActivity"].id : this.window["availableActivity"].id);
        this.window["currentMatMenuValueTracker"].reset(isTwilioOutboundCalls ? "MAKING_CALLS" : "TWILIO_CALLS");
      }
    }

  };

  initiationTwilioWorker(isTwilioOutboundCalls: boolean = false, token: string) {
    /** Create Twilio Worker */
    this.workerInstance = new this.Twilio.TaskRouter.Worker(
      token,
      false/*debug - JS SDK will output events to console  */,
      isTwilioOutboundCalls ? this.window["makingCallsActivity"].id : this.window["availableActivity"].id /*connectActivitySid - ActivitySid to place the worker in upon connecting */,
      this.window["offlineActivity"].id /* ActivitySid to place the worker in upon disconnecting */,
      true /* close existing connections for a given channel  */,
      null,
      10/*Max retries */);
    this.completeAcceptedReservations(() => {
      this.registerWorkerCallbacks();
    })
  }

  public connectTwilioDevice(workerName: string) {
    this.twilioDetails.workerName = workerName;
    this.twilioWorkerService.getTwilioDeviceToken(workerName).then((result: getTwilioDeviceOrWorkerToken) => {
      if (result && result.token) {
        const deviceOptions: any = {
          // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
          // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
          codecPreferences: ["opus", "pcmu"],
          // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
          // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
          // a second time and sending the tone twice. This will be default in 2.0.
          fakeLocalDTMF: true,
          // Use `enableRingingState` to enable the device to emit the `ringing`
          // state. The TwiML backend also needs to have the attribute
          // `answerOnBridge` also set to true in the `Dial` verb. This option
          // changes the behavior of the SDK to consider a call `ringing` starting
          // from the connection to the TwiML backend to when the recipient of
          // the `Dial` verb answers.
          enableRingingState: true,
          closeProtection: true,
          // disableAudioContextSounds: true,
          // sounds: {
          //     incoming: "https://api.twilio.com/cowbell.mp3",
          //     outgoing: "https://api.twilio.com/cowbell.mp3",
          // },
          backoffMaxMs: 60000,
          maxCallSignalingTimeoutMs: 30000,
          // tokenRefreshMs: 10000
        };
        this.deviceInstance = new Device(result.token, deviceOptions);
        this.registerDeviceCallbacks();
        this.twilioDetails.device = this.deviceInstance;
        /** Register Twilio Device */
        this.deviceInstance.register();
      }
    });
  }

  public disconnectAssociatedTwilioDevice = () => {
    if (typeof this.twilioDetails?.device?.destroy === "function") {
      this.twilioDetails.device.destroy();
    }

    if (this.workerInstance?.update && typeof this.workerInstance?.update === "function") {
      this.workerInstance?.update("ActivitySid", this.offlineActivity.id, (error: any | null, worker: workerOfWorkerInstance) => {
        if (error) {
          console.log(error.code);
          console.log(error.message);
        }
        // Remove all references to the worker instance        
      });
    }
    this.window["isMicroServiceSocketConnected"] = false;
    this.window["twilioWorkerRegistered"] = false;
    this.window["twilioDeviceConnected"] = false;
    // this.workerInstance = null;
    this.window["windowTwilioWorkerInstance"] = undefined;

  }

  public sendDigits(digit: string) {
    if (window && this.window["currentCallConnection"] &&
      typeof this.window["currentCallConnection"].sendDigits === "function") {
      this.window["currentCallConnection"].sendDigits(digit);
    }
  }

  unHoldCallerStatus(taskAttributes: attributes) {
    this.apisService.unHoldUserStatus({
      conferenceId: taskAttributes.conference.sid,
      callerId: taskAttributes.conference.participants.customer,
      isHold: false,
      twilioToNumber: taskAttributes.to
    });
  }

  registerWorkerCallbacks() {
    this.workerInstance.on("ready", (worker: workerOfWorkerInstance) => {
      if (this.window["intervalId"]) {
        clearInterval(this.window["intervalId"]);
      }
      this.window["currentMatMenuValueTracker"].reset(this.userStatus);

      if (this.userStatus !== "TWILIO_CALLS" && this.userStatus !== "MAKING_CALLS"){
        this.window.location.reload();
        this.localService.sendTwilioCallNotification({
          reservation: {} as reservation,
          callStatus: "WORKER_OFFLINE"
        });
      }
      else {
        this.localService.sendTwilioCallNotification({
          reservation: {} as reservation,
          callStatus: "WORKER_READY"
        });
      }
      this.twilioDetails.worker = worker;
      this.window["twilioWorkerRegistered"] = true;
      this.window["windowTwilioWorkerInstance"] = this.twilioDetails;
    });

    this.workerInstance.on("activity.update", (worker: workerOfWorkerInstance) => {
      if (worker.activityName === "AfterCall" && this.pendingReservations) {
        worker.update("ActivitySid", this.window["availableActivity"].id);
        this.pendingReservations = false;
        this.localService.sendTwilioCallNotification({
          reservation: {} as reservation,
          callStatus: "ACTIVITY_RESET"
        });
      }

    });

    this.workerInstance.on("reservation.accepted", (reservation: reservation) => {
      if (this.workerInstance && this.onCallActivity?.id) {
        this.workerInstance.update("ActivitySid", this.onCallActivity.id, function (error: any | null, worker: workerOfWorkerInstance) {
          if (error) {
            console.log(error.code);
            console.log(error.message);
          }
        });
      }
      /** Send a notification to Call-modal-component */
      reservation = this.extractFromToAndCallerName(reservation)
      this.localService.sendTwilioCallNotification({
        reservation: reservation,
        callStatus: "RESERVATION_ACCEPTED"
      });

      if (reservation.task.attributes.isTransferTask) {
        this.unHoldCallerStatus(reservation.task.attributes);
      }
    });

    this.workerInstance.on("reservation.canceled", (reservation: reservation) => {
      if (this.window["windowTwilioWorkerInstance"] && this.window["windowTwilioWorkerInstance"].device &&
        typeof this.window["windowTwilioWorkerInstance"].device.disconnectAll === "function") {
        this.window["windowTwilioWorkerInstance"].device.disconnectAll();
      }

      this.localService.sendTwilioCallNotification({
        reservation: reservation,
        callStatus: "RESERVATION_CANCELED"
      });
    });

    this.workerInstance.on("reservation.completed", (reservation: reservation) => {
      if (this.window["windowTwilioWorkerInstance"] && this.window["windowTwilioWorkerInstance"].device &&
        typeof this.window["windowTwilioWorkerInstance"].device.disconnectAll === "function") {
        this.window["windowTwilioWorkerInstance"].device.disconnectAll();
      }

      if (this.workerInstance?.update && this.window["afterCallActivity"]?.id) {
        this.workerInstance.update("ActivitySid", this.window["afterCallActivity"].id, function (error: any, worker: workerOfWorkerInstance) {
          if (error) {
            console.log(error.code);
            console.log(error.message);
          }
        });
      }
      reservation = this.extractFromToAndCallerName(reservation)
      this.localService.sendTwilioCallNotification({
        reservation: reservation,
        callStatus: "RESERVATION_ENDED"
      });
    })

    this.workerInstance.on("reservation.created", (reservation: reservation) => {
      if (!this.window["isMicroServiceSocketConnected"] ||
        !this.window["microServiceTwilioSocketConnection"] ||
        !this.window["microServiceTwilioSocketConnection"]?.connected) {
        this.localService.initiateTwilioSocketConnection(this.window["oauthUserId"]);
      }
      this.localService.sendTwilioCallNotification({
        reservation: reservation,
        callStatus: "RESERVATION_CREATED",
        __this: Object.assign({}, JSON.parse(JSON.stringify(this.workerInstance)), reservation, {
          apiRequester: this.workerInstance.apiRequester,
          resourceUrl: this.workerInstance.resourceUrl,
          token: this.workerInstance.token,
          workspaceUrl: this.workerInstance.workspaceUrl,
          // postWorkActivitySid: this.afterCallActivity.id
        })
      });

      if (!reservation.task.attributes.isOutboundConferenceTask &&
        this.window["currentMatMenuValueTracker"].getConstantValue() === "MAKING_CALLS") {
        this.window["currentMatMenuValueTracker"].reset("TWILIO_CALLS");
        this.window["currentMatMenuValueTracker"].resetMatMenuAndChannelLog();
      }
    });

    this.workerInstance.on("reservation.wrapup", (reservation: reservation) => {
      this.workerInstance.completeTask(reservation.task.sid);
      reservation = this.extractFromToAndCallerName(reservation);
      this.localService.sendTwilioCallNotification({
        reservation: reservation,
        callStatus: "RESERVATION_ENDED"
      });
    });

    this.workerInstance.on("reservation.timeout", (reservation: reservation) => {
      reservation = this.extractFromToAndCallerName(reservation)
      this.localService.sendTwilioCallNotification({
        reservation: reservation,
        callStatus: "RESERVATION_TIMEOUT"
      });
    });

    this.workerInstance.on("disconnected", () => {
      // this.disconnectAssociatedTwilioDevice();
    });

    this.workerInstance.on("token.expired", () => {
      this.twilioWorkerService.getTwilioWorkerToken(this.twilioDetails.workerId).then((result: getTwilioDeviceOrWorkerToken) => {
        if (result && result.token) {
          //Updating worker token
          this.workerInstance.updateToken(result.token);
        };
      })
    });
    this.workerInstance.on("task.updated", (data: any) => {
    });

  }


  private extractFromToAndCallerName(reservationObject: reservation) {
    const { attributes } = reservationObject.task;
    const { from, to, caller_name } = attributes;
    const taskId = reservationObject.taskSid;
    const workerId = reservationObject.workerSid;
    const workSpaceId = reservationObject.workspaceSid;

    const extractedObject = this.assignPrototypes(reservationObject);
    Object.assign(extractedObject, {
      from,
      to,
      callerName: caller_name,
      isPullBased: false,
      workSpaceId,
      workerId,
      taskId,
    });

    return extractedObject;
  }

  private assignPrototypes(obj: Record<string, any>) {
    const newObj = Object.create(obj["__proto__"]);
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        newObj[key] = this.assignPrototypes(obj[key]);
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  private registerDeviceCallbacks = () => {
    this.deviceInstance.on("registered", () => {
      this.window["isMicroServiceSocketConnected"] = true;
      this.window["twilioDeviceConnected"] = true;
    });

    this.deviceInstance.on("incoming", (conn: any) => {
      /** Set a callback to be executed when the connection is accepted */
      conn.accept(() => {
      });
      this.window["currentCallConnection"] = conn;
    });

    this.deviceInstance.on("disconnect", (conn: any) => {
      this.window["currentCallConnection"] = null;
    })

    this.deviceInstance.on("tokenWillExpire", () => {
      this.twilioWorkerService.getTwilioDeviceToken(this.twilioDetails.workerName).then((result: getTwilioDeviceOrWorkerToken) => {
        if (result && result.token) {
          this.deviceInstance.updateToken(result.token);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.disconnectAssociatedTwilioDevice();
  }
};
